<template>
  <div>
    <Divider dashed style="color:#fff">设置概览</Divider>
    <div>
      <h4 class="workplatform-title m-t-10 m-b-20">在刊详情（当前订单在刊数量）</h4>
      <Table
        stripe
        size="small"
        :data="list"
        :columns="tableColumns"
      ></Table>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      tableColumns: [
        { title: '产品类型', key: 'name' },
        {
          title: '在刊数',
          align: 'right',
          render: (h, params) => {
            return h('span', params.row.totalQuantity)
          }
        }
      ]
    }
  },
  computed: {
    list () {
      return this.$store.state.installSetting.orderProductArray
    }
  }
}
</script>
